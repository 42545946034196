import React, { Component } from "react";

export class NewLine extends Component {
  state = {
    id: this.props.line.id,
    value: this.props.line.value,
    displayInput: this.props.line.displayInput
  }

  focusInput = component => {
    if (component) {
      component.focus();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="prompt-line">
          <div className="prompt-character">&gt;</div><div className={this.badgeChange()}>{this.props.displayInput && (
            <input
              type="text"
              className="here"
              ref={this.focusInput}
              value={this.state.value}
              onChange={this.handleChange}
              onKeyDown={this.handleEnter}
              autoFocus
            />
          )}
          {this.state.value}
          </div>
        </div>
      </React.Fragment>
    )
  }

  handleChange = event => {
    this.setState({ value: event.target.value });
    console.log(event.target.value);
  }

  handleEnter = event => {
    if (event.key === "Enter") {
      this.props.handleInput(event.target.value, this.state.id);
    }
  }

  badgeChange() {
    let badge = "prompt-new ";
    badge += this.props.displayInput ? "prompt output new-output" : "prompt-new";
    return badge;
  }
}

export default NewLine;
