import React, { Component } from "react";

export class Ls extends Component {
  state = {
    type: this.props.line.type,
    value: this.props.line.value
  }

  render() {
    return (
      <React.Fragment>
        <div className="prompt-line">
          <div className="prompt-character">&gt;</div><div className="prompt"> {this.props.line.type} </div>
          <ul className="result">
            {this.props.subDir.map(l => (
              <li key={l.id} >
                {l.type}
              </li>
            ))}
          </ul>
        </div>
      </React.Fragment>
    )
  }
}

export default Ls;
